import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";
import { removeNode, selectCurrentNode } from "../node-tree-records.slice";
import NodeEditor from "./node-editor/NodeEditor";
import NodeHeader from "./node-editor/NodeHeader";
import NodeLicenses from "./node-licenses/NodeLicenses";
import "./SelectedItem.scss";
import { useIntl } from "react-intl";
import NodeUsers from "./node-users/NodeUsers";
import { Tabs } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect } from "react";

const SelectedItem = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const selectedNode = useSelector((state: RootState) =>
    selectCurrentNode(state)
  );

  useEffect(() => {
    return () => {
      if (selectedNode?.newNode) {
        dispatch({ type: removeNode.type, payload: selectedNode.id });
      }
    };
  }, [selectedNode?.id]);

  const nodeLoading = useSelector(
    (state: RootState) => state.nodeRecords.nodeLoading
  );

  const tabs = [
    {
      label: formatMessage({ id: "nodes.node.tabs.users" }),
      key: "users",
      children: <NodeUsers />,
    },
    {
      label: formatMessage({ id: "nodes.node.tabs.licenses" }),
      key: "licenses",
      children: <NodeLicenses />,
    },
  ];

  return (
    <div className="selected-item">
      {selectedNode && (
        <>
          <NodeHeader />
          <NodeEditor />
          {nodeLoading ? (
            <div className="selected-item__loading">
              <LoadingOutlined />
            </div>
          ) : (
            <Tabs items={tabs} />
          )}
        </>
      )}
    </div>
  );
};

export default SelectedItem;
