import { Form, Input, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";
import {
  NodeChangeableData,
  nodeRecordsSelectors,
  selectChildrenIdsRecursive,
  selectCurrentNode,
  setFormInvalid,
  setPendingChanges,
} from "../../node-tree-records.slice";
import "./NodeEditor.scss";
import { NodeType } from "../../node-tree.defenitions";

const NodeEditor = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const editCurrentNode = useSelector(
    (state: RootState) => state.nodeRecords.editCurrentNode
  );

  const selectedNode = useSelector(selectCurrentNode);

  const childrenIds = useSelector(selectChildrenIdsRecursive);
  const allIds = useSelector((state: RootState) =>
    nodeRecordsSelectors.selectIds(state.nodeRecords)
  );

  const [form] = useForm();

  const [disabledParentId, setDisabledParentId] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      name:
        selectedNode?.type === NodeType.GRADE
          ? formatMessage({
              id: selectedNode?.name
                ? `grades.${selectedNode.name}`
                : "nodes.newNames.grade",
            })
          : selectedNode?.name,
      externalId: selectedNode?.externalId,
      parentNodeId: selectedNode?.parentNodeId,
    });

    if (
      selectedNode?.type === NodeType.SCHOOL ||
      selectedNode?.type === NodeType.GROUP
    ) {
      setDisabledParentId(false);
    } else {
      setDisabledParentId(true);
    }
  }, [selectedNode]);

  const onNodeUpdate = async (
    _: Partial<NodeChangeableData>,
    all: Partial<NodeChangeableData>
  ) => {
    form
      .validateFields()
      .then(() => {
        // if form is valid, set pending changes
        dispatch({
          type: setPendingChanges.type,
          payload: all,
        });
      })
      .catch((error) => {
        const hasErrors = error.errorFields.length > 0;
        if (hasErrors) {
          dispatch({
            type: setFormInvalid.type,
            payload: true,
          });
        } else {
          dispatch({
            type: setPendingChanges.type,
            payload: all,
          });
        }
      });
  };

  return (
    <div className="node-editor">
      <Form
        form={form}
        onValuesChange={onNodeUpdate}
        validateTrigger={["onChange", "onBlur"]}
        layout="inline"
      >
        <div style={{ display: "flex", flexWrap: "nowrap" }}>
          <Form.Item
            name="name"
            label={formatMessage({ id: "nodes.name" })}
            rules={[{ required: true }]}
            style={{ width: "200px", flex: 1, marginRight: "16px" }}
          >
            {selectedNode?.type !== NodeType.GRADE ? (
              <Input
                className="node-editor__input"
                type="text"
                disabled={!editCurrentNode}
                placeholder={formatMessage({ id: "nodes.name" })}
              />
            ) : (
              <Select
                className="node-editor__input"
                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                  (grade) => {
                    return {
                      label: <FormattedMessage id={`grades.${grade}`} />,
                      value: grade,
                    };
                  }
                )}
              />
            )}
          </Form.Item>
          <Form.Item
            name="externalId"
            label={formatMessage({ id: "nodes.externalId" })}
            style={{ width: "200px", flex: 1, marginRight: "16px" }}
          >
            <Input
              type="text"
              className="node-editor__input"
              disabled={!editCurrentNode}
              placeholder={formatMessage({ id: "nodes.externalId" })}
            />
          </Form.Item>
          <Form.Item
            name="parentNodeId"
            label={formatMessage({ id: "nodes.parentId" })}
            style={{ width: "200px", flex: 1 }}
            rules={[
              {
                validator: async (_, value) => {
                  if (!editCurrentNode || disabledParentId) {
                    return Promise.resolve();
                  }

                  if (value === undefined || value === null || value === "") {
                    throw new Error(
                      formatMessage({ id: "nodes.invalidParent" })
                    );
                  }
                  if (childrenIds.includes(Number(value))) {
                    throw new Error(
                      formatMessage({ id: "nodes.invalidParent" })
                    );
                  }
                  if (value == selectedNode?.id) {
                    throw new Error(
                      formatMessage({ id: "nodes.invalidParent" })
                    );
                  }
                  if (!allIds.includes(Number(value))) {
                    throw new Error(
                      formatMessage({ id: "nodes.invalidParent" })
                    );
                  }
                },
              },
            ]}
          >
            <Input
              type="number"
              className="node-editor__input"
              disabled={!editCurrentNode || disabledParentId}
              placeholder={formatMessage({ id: "nodes.parentId" })}
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default NodeEditor;
